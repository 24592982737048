import { Dictionary } from 'utils/dictionary';

const dateFormatters: Dictionary<Intl.DateTimeFormat> = {};

const getDateFormatter = (locale: string, options: Intl.DateTimeFormatOptions) => {
  const key = `${locale}-${JSON.stringify(options)}`;
  if (!dateFormatters[key]) {
    dateFormatters[key] = new Intl.DateTimeFormat(locale, options);
  }
  return dateFormatters[key];
};

export const formatDate = (date: Date, locale: string = 'en-US', options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }): string => {
  const formatter = getDateFormatter(locale, options);
  return formatter.format(date);
};