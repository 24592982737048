import { SEOHeader } from 'components/common/Head/Head'
import DynamicZone from 'components/DynamicZone'
import {
  CmsTextHeaderSection,
  CommonLink,
  Story,
  StrapiComponent,
} from 'framework/strapi/types'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { getLocaleOrDefault } from 'utils/localization'
import translate from 'utils/translate'
import useMetaData from './useMetaData'
import { formatDate } from 'utils/date-time'

interface Props {
  story: Story
}

const StoryPage: FC<Props> = ({ story }) => {
  const router = useRouter()
  const locale = getLocaleOrDefault(router.locale)
  const newsLinks = getNewsLinks(story, locale)

  const { subHeading, title, background } = story
  const textHeaderProps: CmsTextHeaderSection = {
    id: -19827,
    layout: 'Story',
    overline: null,
    __component: 'cms.text-header-section',
    settings: {
      id: -19828,
      __component: 'common.section-settings',
      colorTheme: 'Dark',
      container: 'Default',
      height: 'Screen',
      anchor: '',
      tw_spacing: 'no',
    },
    background,
    heading: title,
    subHeading,
    links: newsLinks,
    channel: null,
  }

  const mainWithHeader: StrapiComponent[] | null = story.main
    ? [textHeaderProps, ...story.main]
    : null

  const meta = useMetaData({ locale, story })
  return (
    <>
      <SEOHeader {...meta} />
      {mainWithHeader && (
        <DynamicZone
          components={mainWithHeader}
          preview={router.isPreview}
          floatingNavigation={true}
        />
      )}
    </>
  )
}

// const getNewsLinks = ({ readingTime, links, author, category }: Story) => {
const getNewsLinks = ({ readingTime, links, author, date, showDate }: Story, locale: string) => {
  const readingTimeInText =
    readingTime && readingTime > 0
      ? translate('storyReadingTime', readingTime.toString())
      : null

  const dateInText = showDate && date ? formatDate(new Date(date), locale) : null

  const ll = [author, readingTimeInText, dateInText].reduce<CommonLink[]>((a, l, i) => {
    if (l) {
      a.push({
        __component: 'common.link',
        id: i * -100,
        title: l.toString(),
        url: { id: `url-${i}`, type: 'Page', src: null },
      })
    }
    return a
  }, [])
  return links?.length ? [...ll, ...links] : ll
}

export default StoryPage
