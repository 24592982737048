import cn from 'classnames'
import RichText from 'components/common/RichText'
import Container from 'components/ui/Container'
import { CmsTextSection } from 'framework/strapi/types'
import React, { FC } from 'react'

interface Props extends CmsTextSection {}

const TextSection: FC<Props> = ({ settings, text }) => {
  // Css
  settings = settings || {}
  const { container = 'Default', colorTheme = 'Default' } = settings

  const css = {
    richTextWrapper: 'max-w-none prose md:prose-lg lg:prose-xl',
  }

  // Color Theme
  if (colorTheme === 'Dark') {
    css.richTextWrapper = cn(css.richTextWrapper, 'prose-color-theme-dark')
  }

  return (
    <Container layout={container === 'Default' ? 'Prose' : container}>
      <RichText className={css.richTextWrapper}>{text}</RichText>
    </Container>
  )
}

export default TextSection
