import React from 'react'
import ProductPage from 'components/Product'
import CategoryPage from 'components/commerce/CategoryPage'
import StoryPage from 'components/cms/StoryPage'
import Page from 'components/cms/Page'
import type { PageProps } from 'framework/common/server/getStaticPages'
import { getStaticPaths as getSp } from 'framework/common/server/getStaticPages'
import { GetStaticPaths } from 'next'

export const getStaticPaths: GetStaticPaths = async (props) => {
  // We don't want to fetch all static paths in development.
  // Comment away if you want to try the build process locally
  if (process.env.NODE_ENV === 'development') {
    return {
      paths: [],
      fallback: 'blocking',
    }
  }
  return getSp(props)
}

export { getStaticProps } from 'framework/common/server/getStaticPages'

const Index = ({ page, category, variant, story }: PageProps) => {
  if (variant) {
    return <ProductPage variant={variant} />
  }
  if (category) {
    return (
      <CategoryPage
        category={category.category}
        facets={category.facets}
        lists={category.lists || []}
      />
    )
  }
  if (story) {
    return <StoryPage story={story} />
  }
  if (page) {
    return <Page page={page} />
  }
  return null
}

export default Index
