import {
  CmsBannerSection,
  CmsTextHeaderSection,
  Page,
} from 'framework/strapi/types'
import { useMemo } from 'react'
import getAbsoluteImageUrl from 'utils/getAbsoluteImageUrl'
import getFirstPublishedLayerFromBackground from 'utils/getFirstPublishedLayerFromBackground'
import { isComponentPublishedInChannel } from 'utils/isInChannel'

interface Props {
  page: Page
  locale: string
}

interface Result {
  title: string
  image?: string
  desc?: string
  canonicalUrl?: string | null
}

const useMetaData = ({ page, locale }: Props): Result => {
  const result = useMemo(() => {
    const r: Result = {
      title: page.metaTitle || page.title,
      desc: page.metaDescription || undefined,
      image: page.metaImage_canto?.url ?? page.metaImage?.url,
      canonicalUrl: page.canonicalUrl,
    }
    if (r.desc && r.image) return r
    if (!page.main?.length) return r
    const c = page.main.find((c) => isComponentPublishedInChannel(locale, c))
    if (!c) return r
    if (c.__component === 'cms.banner-section') {
      const cc = c as CmsBannerSection
      if (!cc.tiles) return r
      for (const t of cc.tiles) {
        if (!r.image) {
          r.image = getFirstPublishedLayerFromBackground(
            locale,
            t.background,
          )?.url
        }
        if (!r.desc) {
          r.desc = [t.overline, t.heading, t.text].filter((x) => x).join(' | ')
        }
        if (r.image && r.desc) break
      }
    } else if (c.__component === 'cms.text-header-section') {
      const cc = c as CmsTextHeaderSection
      if (!r.image) {
        r.image = getFirstPublishedLayerFromBackground(
          locale,
          cc.background,
        )?.url
      }
      if (!r.desc) {
        r.desc = [cc.overline, cc.heading, cc.subHeading]
          .filter((x) => x)
          .join(' | ')
      }
    }
    if (r.image) {
      r.image = getAbsoluteImageUrl(r.image)
    }
    return r
  }, [page, locale])
  return result
}

export default useMetaData
