import { Story } from 'framework/strapi/types'
import { useMemo } from 'react'
import getAbsoluteImageUrl from 'utils/getAbsoluteImageUrl'
import getFirstPublishedLayerFromBackground from 'utils/getFirstPublishedLayerFromBackground'

interface Props {
  story: Story
  locale: string
}

interface Result {
  title: string
  image?: string
  desc?: string
  canonicalUrl?: string | null
}
const useMetaData = ({ story, locale }: Props): Result => {
  const result = useMemo(() => {
    const r: Result = {
      title: story.metaTitle || story.title,
      desc: story.metaDescription || story.subHeading,
      image: story.metaImage_canto?.url ?? story.metaImage?.url,
      canonicalUrl: story.canonicalUrl,
    }
    if (r.image) return r
    r.image = getFirstPublishedLayerFromBackground(
      locale,
      story.background,
    )?.url
    if (r.image) r.image = getAbsoluteImageUrl(r.image)
    return r
  }, [story, locale])
  return result
}

export default useMetaData
