import { SEOHeader } from 'components/common/Head/Head'
import DynamicZone from 'components/DynamicZone'
import { Page as PageType } from 'framework/strapi/types'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { getLocaleOrDefault } from 'utils/localization'
import useMetaData from './useMetaData'

interface Props {
  page: PageType
}

const Page: FC<Props> = ({ page }) => {
  const router = useRouter()
  const locale = getLocaleOrDefault(router.locale)
  const meta = useMetaData({ page, locale })
  return (
    <>
      <SEOHeader {...meta} />
      {page.main && (
        <DynamicZone
          components={page.main}
          preview={router.isPreview}
          floatingNavigation={page.floatingNavigation}
        />
      )}
    </>
  )
}

export default Page
