import SearchComponent from 'components/Search/SearchComponent'
import { Category, Lists } from 'framework/strapi/types'
import React, { FC } from 'react'
import Container from 'components/ui/Container'
import TextSection from 'components/cms/Text/index'
import { SEOHeader } from 'components/common/Head/Head'
import { decode } from 'he'

interface Props {
  category: Category
  facets: string[]
  lists: Lists[]
}

const CategoryPage: FC<Props> = ({ category, facets, lists }) => {
  return (
    <>
      <SEOHeader
        title={decode(category.metaTitle || category.name)}
        desc={category.metaDescription || category.desc || undefined}
        image={category.metaImage_canto?.url ?? category.metaImage?.url}
      />
      <SearchComponent facets={facets} lists={lists} category={category} />

      {/* Category Description */}
      {category.desc && (
        <div className="py-10 bg-primary md:py-14 lg:py-20">
          <Container layout="Fluid">
            <TextSection
              settings={{
                colorTheme: 'Dark',
                container: 'Default',
                __component: 'common.section-settings',
                id: -8376422,
                anchor: null,
                height: 'Default',
                tw_spacing: null,
              }}
              background={null}
              id={-193845758}
              __component="cms.text-section"
              channel={null}
              text={category.desc}
            />
          </Container>
        </div>
      )}
    </>
  )
}

export default CategoryPage
